<template>
    <div class="planOrFeeComplements">

        <!-- DetailsPlanOrFee -->
        <div class="planOrFeeComplements_tool">
            <DetailsPlanOrFee :show-change-period="showChangePeriod" />
        </div>

        <!-- VAT -->
        <div v-if="user.isLogged && product.isTheCurrency.eur" class="planOrFeeComplements_vat">
            <Vat />
        </div>

        <!-- Values -->
        <div v-if="!isPaymentDomainRegistry" class="planOrFeeComplements_values">

            <!--
            <div class="planOrFeeComplements_values_item">
                <div class="planOrFeeComplements_values_item_name">
                    <p>Preço Original</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p>$50.00/{{ periodCharge }}</p>
                </div>
            </div>
            -->

            <!-- Vat -->
            <div v-if="product.isTheCurrency.eur && isChangeVat" class="planOrFeeComplements_values_item">
                <div class="planOrFeeComplements_values_item_name">
                    {{ translateWord('Taxa de VAT') }}
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p>{{ vatFee.percentage }}% ({{ vatFee.isValid ? vatFee.vatNumberCompleted : localeUser.country }})</p>
                </div>
            </div>

            <!-- Total -->
            <div class="planOrFeeComplements_values_item planOrFeeComplements_values_itemTotal">
                <div class="planOrFeeComplements_values_item_name" :class="{planOrFeeComplements_values_item_nameTrialDays: product.isTrialDays}">
                    <p v-if="isPaymentCountryPrice">
                        <span v-if="product.isBuyAnnual">{{ translateWord('Total devido') }}</span>

                        <span v-else>{{ translateWord('Seu Custo') }}</span>
                    </p>
                    <p v-else>{{ translateWord('Total') }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p v-if="product.isTrialDays" class="planOrFeeComplements_values_item_info_trialDays">
                        <span>
                            <del>
                                {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}
                            </del>
                        </span> 
                        <span class="amountEmphasis">
                            {{ product.currencySymbol }}{{ product.trialDaysAmount }}
                        </span>
                        <span class="periodSymbol">
                            {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                        </span>
                    </p>

                    <p v-else-if="hasCouponAndIsValid && !product.isAmountFirstMonth">
                        <span>
                            <del>{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}</del>
                        </span>
                        <span class="periodSymbol">
                            {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                        </span>
                    </p>

                    <p v-else-if="product.isAmountFirstMonth" class="amountEmphasis">
                        <span>
                            {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}
                        </span>
                        <span class="periodSymbol">
                            {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                        </span>
                    </p>

                    <p v-else-if="isPaymentCountryPrice && product.isBuyAnnual" class="amountEmphasis">
                        <span>
                            {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount }}
                        </span>

                        <span class="periodSymbol">
                            {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                        </span>
                        
                        <span class="planOrFeeComplements_values_item_info_saveMoneyAnnual">
                            {{ translateWord('Você salvou') }} 
                            {{ product.currencySymbol }} 
                            {{ (planCountryPrice.annual.amountRef - (product.isTheCurrency.eur ? productAmountPlusVatFee(false, true) : product.amount)).toFixed(2) }}
                        </span>
                    </p>

                    <p v-else class="amountEmphasis">
                        <span>
                            {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee() : product.amount }}
                        </span>
                        <span class="periodSymbol">
                            {{ isPlan ? '/' : '' }} {{ product.periodSymbol }}
                        </span>
                    </p>
                </div>
            </div>

            <!-- Cupom de desconto -->
            <div v-if="hasCouponAndIsValid && !product.isAmountFirstMonth" class="planOrFeeComplements_values_item">
                <div class="planOrFeeComplements_values_item_name">
                    <p>{{ coupon.code }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p class="amountEmphasis">{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(true) : couponFormatAmount }}</p>
                </div>
            </div>

            <!-- Trial Days -->
            <div v-if="product.isTrialDays && !product.isAmountFirstMonth && !hasBundlePlan" class="planOrFeeComplements_values_item">
                <div class="planOrFeeComplements_values_item_name">
                    <p>{{ translateWord('Dias de teste') }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p>{{ product.trialDays }} {{ translateWord('Dias') }}</p>
                </div>
            </div>

            <!-- Valor no primeiro mes -->
            <div v-if="product.isAmountFirstMonth" class="planOrFeeComplements_values_item planOrFeeComplements_values_itemAmountFirstMonth">
                <div class="planOrFeeComplements_values_item_name">
                    <p>{{ translateWord('Primeiro mês') }}</p>
                </div>

                <div class="planOrFeeComplements_values_item_info">
                    <p v-if="hasCouponAndIsValid">
                        <del>{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, false, true) : product.amountFirstMonth }}</del>
                        {{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(true) : product.amountCoupon }}
                    </p>
                    <p v-else>{{ product.currencySymbol }}{{ product.isTheCurrency.eur ? productAmountPlusVatFee(false, false, true) : product.amountFirstMonth }}</p>
                </div>
            </div>
        </div>

        <!-- Domain -->
        <div v-if="isPaymentDomainRegistry" class="planOrFeeComplements_domain">
            <DetailsDomain />
        </div>

        <!-- Card Items -->
        <div class="planOrFeeComplements_addTool" v-if="isBuyTool">
            <div class="planOrFeeComplements_addTool_header">
                <div class="planOrFeeComplements_addTool_header_name">
                    <p>{{ translateWord('Complementos recomendados') }}</p>
                </div>
            </div>

            <div v-show="tools.length > 0">
                <span @click="carousel.page++" class="slideArrow slideNext"><i class="icon icon-arrow-right"></i></span>
                <span @click="carousel.page--" class="slideArrow slidePrev"><i class="icon icon-arrow-left"></i></span>

                <Carousel class="planOrFeeComplements_addTool_items" v-bind="carousel" v-model="carousel.page">
                    <div class="planOrFeeComplements_addTool_items_item" v-for="(item, index) in tools" :key="index">
                        <img class="planOrFeeComplements_addTool_items_item_image" :src="require('../assets/no-image.png')" alt="Sem Imagem" title="Sem Imagem">
                        <h1 class="planOrFeeComplements_addTool_items_item_title">{{ item.name }}</h1>
                        <p class="planOrFeeComplements_addTool_items_item_desc">
                            Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ...
                        </p>

                        <div class="planOrFeeComplements_addTool_items_item_info">
                            <p class="planOrFeeComplements_addTool_items_item_info_price">
                                {{ product.currencySymbol }}{{ item.amount }}
                            </p>

                            <div class="planOrFeeComplements_addTool_items_item_info_actions">
                        <span @click="addTool(item)" class="planOrFeeComplements_addTool_items_item_info_actions_action add">
                            {{ translateWord('Add') }} <i class="icon icon-plus"></i>
                        </span>
                            </div>
                        </div>
                    </div>
                </Carousel>
            </div>
            <div class="planOrFeeComplements_addTool_cartAddEmpty" v-show="tools.length <= 0">
                <p><i class="icon icon-check"></i> {{ translateWord('Todas as ferrametas foram adicionadas no carrinho') }}</p>
            </div>
        </div>

        <!-- MessagesSecurity -->
        <div v-if="user.isLogged && isPlan">
            <MessagesSecurity />
        </div>

        <template v-if="!isPaymentDomainRegistry">
            <!-- Stamps -->
            <div v-if="!['63', '64', '65'].includes(product.id)" class="planOrFeeComplements_stamps">
                <Stamps />
            </div>

            <!-- Coupon -->
            <div v-if="!hasBundlePlan && !hasUpwork" class="planOrFeeComplements_coupon">
                <Coupon />
            </div>

            <!-- Sponsor -->
            <div v-if="Object.keys(userFather).length !== 0 && !isSponsorBuilderall">
                <Sponsor/>
            </div>
        </template>

    </div>
</template>

<script>
import Carousel from 'vue-ssr-carousel';
import ssrCarouselCss from 'vue-ssr-carousel/index.css'

import Helper from '../assets/js/helper';

import Sponsor from '../components/User/Sponsor';
import Coupon from '../components/Coupon';
import Vat from '../components/Vat';
import MessagesSecurity from '../components/MessagesSecurity'
import Stamps from '../components/Stamps'
import DetailsPlanOrFee from '../components/Checkout/DetailsPlanOrFee'
import DetailsDomain from '../components/Checkout/DetailsDomain'

import { mapState, mapGetters, mapMutations } from 'vuex';

export default {
    name: "PlanOrFeeComplements",
    data(){
        return {
            carousel: {
                page: 0,
                'slides-per-page': 3,
                'no-drag': true,
                gutter: 8,
                'paginate-by-slide': true
            },
            discountCode: '',
            toolsAddCart: [],
            tools: [],
            showChangePeriod: false
        }
    },
    created(){
        this.tools = this.product.suggestions;

        if(this.planCountryPrice.hasOwnProperty('annual')) this.showChangePeriod = true;
    },
    computed: {
        ...mapState([
            'isBuyTool',
            'user',
            'userFather',
            'coupon',
            'vatFee',
            'localeUser',
            'isPaymentCountryPrice',
            'planCountryPrice',
            'isPaymentDomainRegistry',
            'isPlan'
        ]),
        ...mapGetters([
            'product',
            'hasCouponAndIsValid',
            'translateWord',
            'isSponsorBuilderall',
            'isChangeVat',
            'productAmountPlusVatFee',
            'hasBundlePlan',
            'hasUpwork'
        ]),
        couponFormatAmount(){
            return Helper.calcDiscount(this.product.amount, this.coupon.value, this.coupon.type)
        },
        periodCharge(){
            return this.recurrencePeriod == 'MONTH' ? 'Mensal' : 'Anual';
        },
        enabledBtnCoupon(){
            return this.discountCode == '' ? true : false;
        },
        amountTotal(){
            let amountItemsList = 0.00;

            this.toolsAddCart.forEach(item => amountItemsList += item.amount);

            if(this.recurrencePeriod == 'YEAR'){
                return ((this.product.amount + amountItemsList) * 12);
            }

            return (this.product.amount + amountItemsList);
        }
    },
    methods: {
        addTool(tool){
            this.toolsAddCart.push(tool);

            this.tools.splice(this.tools.findIndex(item => tool.id == item.id), 1);
        },
        removeTool(tool){
            this.tools.push(tool);

            this.toolsAddCart.splice(this.toolsAddCart.findIndex(item => tool.id == item.id), 1);
        }
    },
    components: {
        Carousel,
        Sponsor,
        Coupon,
        Vat,
        DetailsPlanOrFee,
        MessagesSecurity,
        Stamps,
        DetailsDomain
    }
}
</script>

<style lang="scss">
    @import "../scss/mixins";

    .slideArrow{
        position: absolute;
        top: 50%;
        z-index: 10;
        background: var(--gray-100);
        padding: 2px 4px;
        font-size: 0.5em;
        border-radius: 50%;
        color: var(--white);
        cursor: pointer;

        &:hover{
            background: var(--gray);
        }
    }
    .slideNext{
        right: -40px;
    }
    .slidePrev{
        left: -40px;
    }

    .slick-slider{
        user-select: auto;
    }

    .planOrFeeComplements_tool{
        border-bottom: 1px solid var(--gray-500);
        padding-bottom: 20px;
    }

    .planOrFeeComplements_addTool_header{
        @extend %cartHeader;
    }
    .planOrFeeComplements_addTool_header_name{
        @extend %cartHeaderName;
    }

    .planOrFeeComplements_addTool{
        position: relative;
    }
    .planOrFeeComplements_addTool_header{
        margin-bottom: 20px;
        font-size: 0.9em;
    }

    .customSlickSlider{
        padding: 0px 4px;
    }
    .planOrFeeComplements_addTool_items_item{
        display: flex;
        flex-direction: column;
        background: var(--white);
        padding: 15px 10px;
        border-radius: 5px;
        border: 1px solid #E9E9E9;
    }
    .planOrFeeComplements_addTool_items_item_image{
        border-radius: 5px;
    }
    .planOrFeeComplements_addTool_items_item_title{
        font-size: 0.9em;
        margin-top: 8px;
        margin-bottom: 5px;
        color: var(--blue);
    }
    .planOrFeeComplements_addTool_items_item_desc{
        font-size: 0.7em;
        font-weight: 500;
    }

    .planOrFeeComplements_addTool_items_item_info{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
    }
    .planOrFeeComplements_addTool_items_item_info_price{
        font-weight: bold;
        font-size: 0.9em;
    }
    .planOrFeeComplements_addTool_items_item_info_actions{
        font-size: 0.55em;

        & .icon{
            font-size: 1em;
            vertical-align: middle;
        }
    }
    .planOrFeeComplements_addTool_items_item_info_actions_action{
        padding: 3px 6px;
        background: var(--gray-100);
        color: var(--white);
        border-radius: 10px;
        cursor: pointer;

        &.add:hover{
            background: var(--blue);
        }

        &.delete:hover{
            background: var(--red);
        }
    }

    .planOrFeeComplements_addTool_cartAddEmpty{
        text-align: center;
        font-weight: bold;
        color: #3fd1ae;
        font-size: 1.2em;

        & i{
            font-size: 1.6em;
        }
    }

    .planOrFeeComplements_domain{
        border-bottom: 2px solid var(--gray-100);
        padding: 32px 10px 32px 10px;
    }

    .planOrFeeComplements_stamps{
        border-top: 2px solid var(--gray-100);
        padding: 20px 10px 0px 10px;
        margin-top: 20px;
    }

    .planOrFeeComplements_coupon{
        margin-top: 25px;
        padding: 25px 0px;
        border-top: 2px solid var(--gray-100);
    }

    .planOrFeeComplements_values{
        display: flex;
        flex-direction: column;
    }
    .planOrFeeComplements_values_item{
        display: flex;
        justify-content: space-between;
        margin: 15px 0px;
        align-items: center;
    }
    .planOrFeeComplements_values_item_name{
        font-size: 0.9em;
        font-weight: 600;

        &.planOrFeeComplements_values_item_nameTrialDays{
            font-size: 1.3em;
        }
    }
    .planOrFeeComplements_values_item_info{
        display: flex;
        align-items: center;
        font-size: 1em;
        font-weight: 600;
        color: #505050;
    }

    .planOrFeeComplements_values_itemTotal{
        & .planOrFeeComplements_values_item_info{
            & p:first-child{
                margin-right: 10px;
                margin-top: 10px;
            }
        }
    }
    .planOrFeeComplements_values_itemAmountFirstMonth .planOrFeeComplements_values_item_info,
    .planOrFeeComplements_values_item_info .planOrFeeComplements_values_item_info_trialDays{
        & del{
            font-size: 0.7em;
            color: var(--gray-100);
        }
    }

    .planOrFeeComplements_values_item_info_saveMoneyAnnual{
        display: block;
        font-size: 0.5em;
        text-align: right;
        color: var(--blue);
    }

    @include media(sm,md,lg){
        .planOrFeeComplements_tool_items_item{
            flex-direction: column;
        }

        .planOrFeeComplements_tool_items_item_price{
            margin-top: 20px;
        }
    }

    @include media(sm,md){
        .PlanOrFeeComplements{
            margin-bottom: 40px;
        }
    }
</style>